const WEEKLY_REPORT_PATH = 'weekly-report-policies/v1';
const DAILY_REPORT_PATH = 'daily-report-policies/v1';
const INTEGRATIONS_ALL_PATH = 'integrations/v1';
const INTEGRATIONS_ENABLED_PATH = `${INTEGRATIONS_ALL_PATH}/enabled`;
const INTEGRATIONS_AVAILABLE_PATH = `${INTEGRATIONS_ALL_PATH}/available`;
const INTEGRATION_SMS_PATH = `${INTEGRATIONS_ALL_PATH}/sms`;
const INTEGRATIONS_EMAIL_PATH = `${INTEGRATIONS_ALL_PATH}/email`;
const INTEGRATIONS_DAILY_TOP_DETECTIONS_REPORT_PATH = `${INTEGRATIONS_ALL_PATH}/daily-top-detections-report`;
const SYSLOG_PATH = `${INTEGRATIONS_ALL_PATH}/syslog`;
const INTEGRATIONS_SERVER_STATUS_PATH = `${SYSLOG_PATH}/status`;
const TEST_SYSLOG_CONNECTION_PATH = `${SYSLOG_PATH}/test-connection`;
const SENTINEL_PATH = `${INTEGRATIONS_ALL_PATH}/sentinel`;
const SENTINEL_SERVER_STATUS_PATH = `${SENTINEL_PATH}/status`;

export {
	DAILY_REPORT_PATH,
	INTEGRATIONS_ALL_PATH,
	INTEGRATIONS_AVAILABLE_PATH,
	INTEGRATIONS_DAILY_TOP_DETECTIONS_REPORT_PATH,
	INTEGRATIONS_EMAIL_PATH,
	INTEGRATIONS_ENABLED_PATH,
	INTEGRATIONS_SERVER_STATUS_PATH,
	INTEGRATION_SMS_PATH,
	SYSLOG_PATH,
	TEST_SYSLOG_CONNECTION_PATH,
	WEEKLY_REPORT_PATH,
	SENTINEL_PATH,
	SENTINEL_SERVER_STATUS_PATH,
};

