import { CircularProgress, Grid, Typography } from '@material-ui/core';
import {
	CONFIG_SUBTITLE,
	CONFIG_TITLE,
	CREATE_SENTINEL_BUTTON_TEXT,
	FORM_FIELDS,
	formValidationSchema,
	INTEGRATION_SETTING_TITLE,
	SAVE_ERROR_MESSAGE,
	SAVE_SUCCESS_MESSAGE,
} from './const';
import TextFieldWrapper from '../../../../../shared/components/form/textFieldWrapper/TextFieldWrapper';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SubmitButton from '../../../../../shared/components/form/Buttons/SubmitButton';
import { useFormik } from 'formik';
import { openSnackbar } from '../../../../snackbarAlert/controller/redux/actions';
import { useDispatch } from 'react-redux';
import { useStyles } from './sentinelIntegrationForm.css';
import { createSentinelIntegration, getAllIntegrations } from '../../../controller/services/sentinel';
import { SENTINEL } from '../../../controller/constants/integrationTypes';
import { LOW_SEVERITY_VALUE } from '../syslog/const';
import { SNACKBAR_HIDE_DURATION } from '../../../../backgroundProcess/view/visibilityView/rawdataExport/constants';

const SentinelIntegrationForm = ({ onSubmit }) => {
	const [isWatchMode, setIsWatchMode] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const dispatch = useDispatch();
	const classes = useStyles();
	const [config, setConfig] = useState(null);

	useEffect(() => {
		async function getConfiguration() {
			try {
				const accountIntegrations = await getAllIntegrations();
				if (accountIntegrations && accountIntegrations.integrations[SENTINEL]) {
					setConfig(accountIntegrations.integrations[SENTINEL]);
					setIsWatchMode(true);
				} else {
					setIsWatchMode(false);
					setConfig(null);
				}
			} catch {
				setIsWatchMode(false);
				setConfig(null);
			}
		}

		getConfiguration();
	}, []);

	const {
		values,
		handleChange,
		touched,
		errors,
		handleSubmit,
		handleBlur,

	} = useFormik({
		initialValues: config || {},
		enableReinitialize: true,
		validateOnChange: true,
		validateOnBlur: true,
		validationSchema: isWatchMode ? false : formValidationSchema,
		async onSubmit(result) {
			setIsLoading(true);

			try {
				await createSentinelIntegration({
					...result,
					severity: LOW_SEVERITY_VALUE,
					shouldSendDetections: true,
					shouldSendAudit: false,
				});
				dispatch(openSnackbar('success', SAVE_SUCCESS_MESSAGE, SNACKBAR_HIDE_DURATION));
				await onSubmit();
			} catch {
				dispatch(openSnackbar('error', SAVE_ERROR_MESSAGE, SNACKBAR_HIDE_DURATION));
			} finally {
				setIsLoading(false);
			}
		},
	});

	return (
		<form onSubmit={handleSubmit}>
			<Grid className={classes.form}>
				<Typography className={classes.configTitle}>
					{CONFIG_TITLE}
				</Typography>
				<Typography className={classes.configSubTitle}>
					<span>
						{CONFIG_SUBTITLE}
					</span>
				</Typography>
				<Typography className={classes.sectionTitle}>
					{INTEGRATION_SETTING_TITLE}
				</Typography>
				<Grid container spacing={2}>
					<Grid item container spacing={2}>
						{FORM_FIELDS.map(field =>
							<Grid item xs={6} key={field.key}>
								<TextFieldWrapper
									fullWidth
									id={field.key}
									name={field.key}
									label={field.label}
									value={values[field.key]}
									error={touched[field.key] && Boolean(errors[field.key])}
									helperText={touched[field.key] && errors[field.key]}
									onChange={handleChange}
									onBlur={handleBlur}
									InputLabelProps={{ shrink: config ? config[field.key] : values[field.key] }}
									required
								/>
							</Grid>,
						)}
					</Grid>
				</Grid>
				<div className={classes.footer}>
					{isLoading ? (
						<CircularProgress color="primary"/>
					) : (
						<SubmitButton
							label={CREATE_SENTINEL_BUTTON_TEXT}
							className={classes.sendButton}/>
					)}
				</div>
			</Grid>
		</form>
	);
};

SentinelIntegrationForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
};

export default SentinelIntegrationForm;
