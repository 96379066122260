const SMS = 'sms';
const EMAIL = 'email';
const WEEKLY_REPORT = 'weeklyReport';
const DAILY_REPORT = 'dailyReport';
const DAILY_TOP_DETECTIONS_REPORT = 'dailyTopDetectionsReport';
const CYNAMICS_APIS = 'cynamicsApis';
const ENDPOINT_INVENTORY_REPORTS = 'endpointInventoryReports';
const SYSLOG = 'syslog';
const SENTINEL = 'sentinel';

const integrationLabels = {
	[SMS]: 'Add Notification',
	[EMAIL]: 'Add Notification',
	[WEEKLY_REPORT]: 'Add Weekly Report',
	[DAILY_REPORT]: 'Add Daily Report',
	[CYNAMICS_APIS]: 'Cynamics API',
	[ENDPOINT_INVENTORY_REPORTS]: 'Endpoint inventory',
	[SYSLOG]: 'Add Syslog',
	[DAILY_TOP_DETECTIONS_REPORT]: 'Daily Detections report',
	[SENTINEL]: 'Add Sentinel',
};

const getIntegrationLabel = value => integrationLabels[value];

export {
	CYNAMICS_APIS,
	DAILY_REPORT,
	DAILY_TOP_DETECTIONS_REPORT,
	EMAIL,
	ENDPOINT_INVENTORY_REPORTS,
	SMS,
	SYSLOG,
	WEEKLY_REPORT,
	SENTINEL,
	getIntegrationLabel,
};

