import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	form: {
		padding: '40px 42px 0 42px',
		maxHeight: '83vh',
		overflow: 'scroll',
		overflowX: 'hidden',
	},
	configTitle: {
		fontSize: '16px',
		fontWeight: 'bold',
		color: theme.palette.secondary.main,
		textAlign: 'flex-start',
	},
	configSubTitle: {
		fontSize: '14px',
		color: theme.palette.text.secondary,
		textAlign: 'flex-start',
		marginTop: '24px',
	},
	sectionTitle: {
		fontSize: '14px',
		fontWeight: 'bold',
		color: theme.palette.secondary.main,
		textAlign: 'flex-start',
		margin: '20px 0 16px',
	},
	footer: {
		margin: '20px 0 20px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	sendButton: {
		width: '200px',
		height: '50px',
	},
}));

export { useStyles };
