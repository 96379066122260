import * as yup from 'yup';

export const CONFIG_TITLE = 'MS Sentinel integration';
export const CONFIG_SUBTITLE = 'Add the integration for MS Sentinel integration with the following fields:';
export const INTEGRATION_SETTING_TITLE = 'Integration settings';
export const AZURE_TENANT_ID_FORM_KEY = 'azureTenantId';
export const AZURE_TENANT_ID_FORM_LABEL = 'Azure tenant ID';
export const AZURE_CLIENT_ID_FORM_KEY = 'azureClientId';
export const AZURE_CLIENT_ID_FORM_LABEL = 'Azure client ID';
export const AZURE_CLIENT_SECRET_FORM_KEY = 'azureClientSecret';
export const AZURE_CLIENT_SECRET_FORM_LABEL = 'Azure client secret';
export const DATA_COLLECTION_ENDPOINT_FORM_KEY = 'dataCollectionEndpoint';
export const DATA_COLLECTION_ENDPOINT_FORM_LABEL = 'Data collection endpoint';
export const DATA_COLLECTION_RULE_ID_FORM_KEY = 'dataCollectionRuleId';
export const DATA_COLLECTION_RULE_ID_FORM_LABEL = 'Data collection rule ID';
export const DATA_COLLECTION_STREAM_NAME_FORM_KEY = 'dataCollectionStreamName';
export const DATA_COLLECTION_STREAM_NAME_FORM_LABEL = 'Data collection stream name';
export const CREATE_SENTINEL_BUTTON_TEXT = 'Save';
export const SAVE_ERROR_MESSAGE = 'Something went wrong.. Please try again';
export const SAVE_SUCCESS_MESSAGE = 'Sentinel integration successfully configured';
const AZURE_TENANT_ID_ERROR_MESSAGE = 'Please enter Azure tenant ID';
const AZURE_CLIENT_ID_ERROR_MESSAGE = 'Please enter Azure client ID';
const AZURE_CLIENT_SECRET_ERROR_MESSAGE = 'Please enter Azure client secret';
const DATA_COLLECTION_ENDPOINT_ERROR_MESSAGE = 'Please enter data collection endpoint';
const DATA_COLLECTION_RULE_ID_ERROR_MESSAGE = 'Please enter data collection rule ID';
const DATA_COLLECTION_STREAM_NAME_ERROR_MESSAGE = 'Please enter data collection stream name';
export const formValidationSchema = yup.object({
	[AZURE_TENANT_ID_FORM_KEY]: yup
		.string(AZURE_TENANT_ID_ERROR_MESSAGE)
		.required(AZURE_TENANT_ID_ERROR_MESSAGE),
	[AZURE_CLIENT_ID_FORM_KEY]: yup
		.string(AZURE_CLIENT_ID_ERROR_MESSAGE)
		.required(AZURE_CLIENT_ID_ERROR_MESSAGE),
	[AZURE_CLIENT_SECRET_FORM_KEY]: yup
		.string(AZURE_CLIENT_SECRET_ERROR_MESSAGE)
		.required(AZURE_CLIENT_SECRET_ERROR_MESSAGE),
	[DATA_COLLECTION_ENDPOINT_FORM_KEY]: yup
		.string(DATA_COLLECTION_ENDPOINT_ERROR_MESSAGE)
		.required(DATA_COLLECTION_ENDPOINT_ERROR_MESSAGE),
	[DATA_COLLECTION_RULE_ID_FORM_KEY]: yup
		.string(DATA_COLLECTION_RULE_ID_ERROR_MESSAGE)
		.required(DATA_COLLECTION_RULE_ID_ERROR_MESSAGE),
	[DATA_COLLECTION_STREAM_NAME_FORM_KEY]: yup
		.string(DATA_COLLECTION_STREAM_NAME_ERROR_MESSAGE)
		.required(DATA_COLLECTION_STREAM_NAME_ERROR_MESSAGE),
});

export const FORM_FIELDS = [
	{ key: AZURE_TENANT_ID_FORM_KEY, label: AZURE_TENANT_ID_FORM_LABEL },
	{ key: AZURE_CLIENT_ID_FORM_KEY, label: AZURE_CLIENT_ID_FORM_LABEL },
	{ key: AZURE_CLIENT_SECRET_FORM_KEY, label: AZURE_CLIENT_SECRET_FORM_LABEL },
	{ key: DATA_COLLECTION_ENDPOINT_FORM_KEY, label: DATA_COLLECTION_ENDPOINT_FORM_LABEL },
	{ key: DATA_COLLECTION_RULE_ID_FORM_KEY, label: DATA_COLLECTION_RULE_ID_FORM_LABEL },
	{ key: DATA_COLLECTION_STREAM_NAME_FORM_KEY, label: DATA_COLLECTION_STREAM_NAME_FORM_LABEL },
];
