import React, { Component } from 'react';
import _ from 'lodash';

import {
    XYPlot,
    VerticalGridLines,
    VerticalBarSeries,
    LineSeries,
} from 'react-vis';
import { setAlertPopOver } from '../../../../controller/redux/actions';
import { connect } from 'react-redux';



const verticalGridLinesStyle = {
    stroke: "#dbdfe0",// light-blue-grey
    strokeWidth: 4,
};

const verticalGridLinesStyle3Days = {
    width: 5,
    transform: 'translate(19.5px, 0px)'
}


const sizeReferences = {
    3: 'translate(75px)',
    7: 'translate(55x)',
    14: 'translate(35px)',
    30: 'translate(25px)'
}




class AlertGraph extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedBar: null,
            hasPopOver : false
        }
        this.triggerTimer = this.triggerTimer.bind(this);
        this.onMouseOver = this.onMouseOver.bind(this);
    }
    onMouseOver = (value, event) => {
        if (value.color < 2 && !this.state.hasPopOver) {
            let clientX = event.event.clientX
            let clientY = event.event.clientY
            this.props.setAlertPopOver({ selectedBar: value, isOpen: true, anchorPosition: { 'top': clientY , 'left': clientX - 35 } , hasPopOver :true})
        }
    }
    triggerTimer = ()=> {
        setTimeout(()=> { this.setState({hasPopOver : false})}, 3000);
    }
    render() {
        const { selectedFilter, verticalTick, allArrays, allValues } = this.props;

        return (
            <>
                <XYPlot
                    colorRange={ ['#f05f5a', '#00df5d'] }
                    colorDomain={ [0, 1] }
                    width={ this.props.width }
                    height={ this.props.height }
                    onMouseLeave={ (value, event) => {
                    } }>

                    <VerticalGridLines
                        style={ verticalGridLinesStyle }
                        tickValues={ verticalTick }
                    />

                    <VerticalBarSeries
                        style={ null }
                        barWidth={ selectedFilter.value === 3 ? 0.1 : 0.2 }
                        data={ allValues }
                        onValueClick ={ (value, event) => { this.onMouseOver(value, event) } }
                    />

                    <LineSeries
                        data={ allArrays } color="#ecaa00" />

                </XYPlot>
            </>
        );
    }



}


const mapStateToProps = state => {
    return {

    };
};
const mapDispatchToProps = {
    setAlertPopOver
};
export default connect(mapStateToProps, mapDispatchToProps)(AlertGraph);


