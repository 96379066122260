import EmailIcon from '../../../../assets/integrations/email-integration.svg';
import CheckPoint from '../../../../assets/integrations/serviceProviders/CheckPoint.jpg';
import Fortinet from '../../../../assets/integrations/serviceProviders/Fortinet.jpg';
import SonicWall from '../../../../assets/integrations/serviceProviders/SonicWall.png';
import F5 from '../../../../assets/integrations/serviceProviders/f5network.png';
import PaloAlto from '../../../../assets/integrations/serviceProviders/palo-alto.jpeg';
import Radware from '../../../../assets/integrations/serviceProviders/radware.svg';
import Slack from '../../../../assets/integrations/serviceProviders/slack.svg';
import SMSIcon from '../../../../assets/integrations/sms-integration.svg';
import Syslog from '../../../../assets/integrations/syslog.svg';
import Sentinel from '../../../../assets/integrations/sentinel.png';
import Weekly from '../../../../assets/integrations/weekly-email.svg';
import CynamicsIntegration from '../../../../assets/login/logo-vertical.png';
import { getSyslogServerStatus } from '../services/requests';
import {
	CYNAMICS_APIS,
	DAILY_REPORT,
	DAILY_TOP_DETECTIONS_REPORT,
	EMAIL,
	ENDPOINT_INVENTORY_REPORTS,
	SENTINEL,
	SMS,
	SYSLOG,
	WEEKLY_REPORT,
} from './integrationTypes';
import { getSentinelServerStatus } from '../services/sentinel';

const ACTIVE_BUTTON_TEXT = 'View';
const NONE_BUTTON_TEXT = 'Add';

const integrations = [
	{
		title: 'Alert Email notifications',
		integrationType: EMAIL,
		description: 'Email notifications for each new threat or custom alert as soon as it is discovered',
		icon: EmailIcon,
		isComingSoon: false,
	},
	{
		title: 'Alert SMS notifications',
		integrationType: SMS,
		description: 'SMS notifications for each new threat or custom alert as soon as it is discovered',
		icon: SMSIcon,
		isComingSoon: false,
	},
	{
		title: 'Daily top detections report',
		integrationType: DAILY_TOP_DETECTIONS_REPORT,
		description: 'Sending a report once a day summarizing the top detections found',
		icon: Weekly,
		isComingSoon: false,
		isShowEnable: true,
	},
	{
		title: 'Email weekly report',
		integrationType: WEEKLY_REPORT,
		description: 'Sending a summarized report once a week',
		icon: Weekly,
		isComingSoon: false,
	},
	{
		title: 'Daily Insights Report',
		integrationType: DAILY_REPORT,
		description: 'Sending a summarized report once a day',
		icon: Weekly,
		isComingSoon: false,
	},
	{
		title: 'Endpoint inventory reports',
		integrationType: ENDPOINT_INVENTORY_REPORTS,
		description: 'Generate Endpoint Inventory reports',
		icon: Weekly,
		isComingSoon: false,
	},
	{
		title: 'Syslog configuration',
		description: 'Send detections and alerts to Syslog server',
		integrationType: SYSLOG,
		icon: Syslog,
		isComingSoon: false,
		additionalSettings: {
			activeButtonText: ACTIVE_BUTTON_TEXT,
			noneButtonText: NONE_BUTTON_TEXT,
			statusPollerFn: getSyslogServerStatus,
			isShowMenuOnDisable: true,
		},
		isDelete: true,
		isShowEnable: true,
		deleteMessage: 'Are you sure you want to delete the Syslog configuration?',
	},
	{
		title: 'MS Sentinel',
		description: 'Add integration with MS Sentinel',
		integrationType: SENTINEL,
		icon: Sentinel,
		additionalSettings: {
			activeButtonText: ACTIVE_BUTTON_TEXT,
			noneButtonText: NONE_BUTTON_TEXT,
			statusPollerFn: getSentinelServerStatus,
			isShowMenuOnDisable: true,
		},
		isComingSoon: false,
		isDelete: true,
		deleteMessage: 'Are you sure you want to delete the MS Sentinel integration?',
	},
	{
		title: 'Integrate with cynamics APIs',
		integrationType: CYNAMICS_APIS,
		description: 'Integrate with cynamics APIs',
		icon: CynamicsIntegration,
		isComingSoon: false,
	},
	{
		title: 'Fortinet',
		description: 'Next-generation firewalls filter network traffic to protect an organization from external threats',
		icon: Fortinet,
		isComingSoon: true,
	},
	{
		title: 'Palo Alto Networks',
		description: 'Next-generation firewalls filter network traffic to protect an organization from external threats',
		icon: PaloAlto,
		isComingSoon: true,
	},
	{
		title: 'CheckPoint',
		description: 'Next-generation firewalls filter network traffic to protect an organization from external threats',
		icon: CheckPoint,
		isComingSoon: true,
	},
	{
		title: 'SonicWall',
		description: 'SonicWall Is a firewall that delivers enterprise-grade protection without the cost or complexity',
		icon: SonicWall,
		isComingSoon: true,
	},
	{
		title: 'Slack',
		description: 'Slack brings your communication together. Teamwork in Slack happens in channels: a single place for messaging, tools, and files',
		icon: Slack,
		isComingSoon: true,
	},
	{
		title: 'F5 Networks',
		description: 'F5 Networks is a transnational company that specializes in application services and application delivery networking',
		icon: F5,
		isComingSoon: true,
	},
	{
		title: 'Radware',
		description: 'Radware is a provider of cybersecurity and application delivery solutions for physical, cloud and software-defined data centers',
		icon: Radware,
		isComingSoon: true,
	},
];

export default integrations;
