import http from '../../../../services/httpService/http';
import {
	INTEGRATIONS_ALL_PATH,
	INTEGRATIONS_ENABLED_PATH,
	SENTINEL_PATH,
	SENTINEL_SERVER_STATUS_PATH,
} from '../constants/urls';
import { SENTINEL } from '../constants/integrationTypes';

export const createSentinelIntegration = data => http.post(SENTINEL_PATH, data);
export const getAllIntegrations = () => http.get(INTEGRATIONS_ALL_PATH);
export const deleteSentinelIntegration = () => http._delete(SENTINEL_PATH);
export const activateSentinelIntegration = newValue => http.put(INTEGRATIONS_ENABLED_PATH, { type: SENTINEL, newValue });
export const getSentinelServerStatus = () => http.get(SENTINEL_SERVER_STATUS_PATH);
