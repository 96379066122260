import { useState, useEffect } from 'react';
import { openSnackbar } from '../../containers/snackbarAlert/controller/redux/actions';
import { useDispatch } from 'react-redux';

const getDefaultSort = (key) => {
	const defaults = {
		severity: { key: "severity", direction: "desc" },
	};
	return defaults[key] || { key, direction: "desc" };
};

const DEFAULT_PAGE_ON_FILTER = 1;

const useTable = (
	fetchRequest,
	itemsPerPage,
	pagesToPreload,
	defaultSortKey,
	defaultSortDirection,
	filters,
) => {
	const dispatch = useDispatch();

	const initialSort = getDefaultSort(defaultSortKey);
	const [isInit, setIsInit] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalItems, setTotalItems] = useState(0);
	const [items, setItems] = useState([]);
	const [startRow, setStartRow] = useState(0);
	const [endRow, setEndRow] = useState(itemsPerPage - 1);
	const [sortKey, setSortKey] = useState(initialSort.key);
	const [sortDirection, setSortDirection] = useState(initialSort.direction);
	const [isLoading, setIsLoading] = useState(false);

	const runFetchRequest = async (
		startPage,
		endPage,
		sortKey,
		sortDirection,
		filters,
	) =>
		fetchRequest(
			startPage,
			endPage,
			sortKey,
			sortDirection,
			filters,
		);

	useEffect(() => {
		const fetchItems = async () => {
			try {
				setIsLoading(true);
				const response = await runFetchRequest(
					currentPage,
					currentPage + pagesToPreload,
					sortKey,
					sortDirection,
					filters,
				);
				setItems([...items, ...response.results]);
				setTotalItems(response.totalResults);
			} catch {
				dispatch(openSnackbar('error', 'Unable to show items right now', 4000));
			} finally {
				setIsLoading(false);
				setIsInit(true);
			}
		};

		fetchItems();
		setCurrentPage(1);
	}, [itemsPerPage]);

	useEffect(() => {
		const fetchItems = async () => {
			const lastPreloadedPage = Math.ceil(items.length / itemsPerPage);
			if (lastPreloadedPage > 0) {
				try {
					setIsLoading(true);
					const response = await runFetchRequest(
						lastPreloadedPage + 1,
						lastPreloadedPage + pagesToPreload,
						sortKey,
						sortDirection,
						filters,
					);
					setItems([...items, ...response.results]);
					setTotalItems(response.totalResults);
				} catch {
					dispatch(
						openSnackbar('error', 'Unable to show more items right now', 4000),
					);
				} finally {
					setIsLoading(false);
				}
			}
		};

		if (shouldFetchItems()) {
			fetchItems();
		}

		const [newStartRow, newEndRow] = calculatePageIndexes(currentPage);
		setStartRow(newStartRow);
		setEndRow(newEndRow);
	}, [currentPage, itemsPerPage]);

	useEffect(() => {
		const fetchItems = async () => {
			try {
				setIsLoading(true);
				setCurrentPage(DEFAULT_PAGE_ON_FILTER);
				const response = await runFetchRequest(
					DEFAULT_PAGE_ON_FILTER,
					DEFAULT_PAGE_ON_FILTER + pagesToPreload,
					sortKey,
					sortDirection,
					filters,
				);
				setItems(response.results);
				setTotalItems(response.totalResults);
			} catch {
				dispatch(openSnackbar('error', 'Unable to show items right now', 4000));
			} finally {
				setIsLoading(false);
			}
		};

		if (!isLoading && isInit) {
			fetchItems();
		}
	}, [filters]);

	const shouldFetchItems = () => 
		currentPage === Math.ceil(items.length / itemsPerPage) && 
		(Math.ceil(items.length / itemsPerPage) !== Math.ceil(totalItems / itemsPerPage));

	const calculatePageIndexes = pageNumber => {
		const startIndex = (pageNumber - 1) * itemsPerPage;
		let endIndex = startIndex + itemsPerPage - 1;
		if (items.length > 0 && endIndex > items.length - 1) {
			endIndex = items.length - 1;
		}

		return [startIndex, endIndex];
	};

	const handlePageChange = (event, pageNumber) => {
		setCurrentPage(pageNumber);
		const [startRow, endRow] = calculatePageIndexes(pageNumber);
		setStartRow(startRow);
		setEndRow(endRow);
	};

	const handleSort = async (event, currentSortKey) => {
		setIsLoading(true);
		const isSameColumn = currentSortKey === sortKey;
		const newSortDirection = isSameColumn ? (sortDirection === 'asc' ? 'desc' : 'asc') : getDefaultSort(currentSortKey).direction;

		try {
			const response = await runFetchRequest(
				1,
				currentPage + pagesToPreload,
				currentSortKey,
				newSortDirection,
				filters,
			);
			setItems(response.results);
			setTotalItems(response.totalResults);
			setSortDirection(newSortDirection);
			setSortKey(currentSortKey);
		} catch {
			dispatch(openSnackbar('error', 'Unable to sort items right now', 4000));
		} finally {
			setIsLoading(false);
		}
	};

	const refetchPages = async () => {
		try {
			const { results, totalResults } = await runFetchRequest(
				currentPage,
				currentPage + pagesToPreload,
				sortKey,
				sortDirection,
				filters,
			);
			setItems([...items.slice(0, (currentPage * itemsPerPage) - itemsPerPage), ...results]);
			setTotalItems(totalResults);
			const [newStartRow, newEndRow] = calculatePageIndexes(currentPage);
			setStartRow(newStartRow);
			setEndRow(newEndRow);
		} catch {
			dispatch(openSnackbar('error', 'Unable to update items right now', 4000));
		}
	};

	const replaceItem = (item, index) => {
		const updatedItems = [...items];
		updatedItems[index] = item;
		setItems(updatedItems);
	};

	return {
		items,
		currentPage,
		totalItems,
		startRow,
		endRow,
		sortKey,
		sortDirection,
		isLoading,
		handlePageChange,
		handleSort,
		refetchPages,
		replaceItem,
	};
};

export default useTable;
